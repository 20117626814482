import * as React from "react";
import { Table, Spin, BackTop, Tooltip, Button, Tabs, Popconfirm, Radio } from "antd";
import { EyeOutlined, FileTextOutlined, SaveOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import { showNotification } from "../utils/index";
import { getDownloadSignedUrl } from "../utils/s3_helper";
import { AuthContext } from "../components/authProvider";
const { TabPane } = Tabs;


export const GET_TESTS = gql`
  query getTests {
    new_tests: test_result(where: {status: {_eq: New}}) {
      uuid
      test_date
      kit_no
      kit {
        test_type
        kit_type
        kit_name
        manufacturer
      }
      result
      remarks
      image
      lab_report      
      lab_subject {
        lab {
          lab_name
          certificate_no
          address
          validity
        }
      }
    }
    validated_tests: test_result(where: {status: {_neq: New}}) {
      test_date
      kit_no
      kit {
        test_type
        kit_type
        kit_name
        manufacturer
      }
      result
      remarks
      image
      lab_report      
      lab_subject {
        lab {
          lab_name
          certificate_no
          address
          validity
        }
      }
      status
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation updateStatus($uuid: uuid!, $status: test_status_enum!) {
    update_test_result(where: { uuid: { _eq: $uuid } }, _set: { status: $status }) {
      returning {
        status
        result
        kit {
          test_type
        }
        lab_subject {
          subject {
            uuid
          }
        }
      }
    }
  }
`;

const UPDATE_SUBJECT = gql`
  mutation updateSubject($uuid: uuid!, $obj: subject_set_input!) {
    update_subject(where: { uuid: { _eq: $uuid } }, _set: $obj) {
      returning {
        uuid
      }
    }
  }
`;

export default function Tests(props) {
  const authState = React.useContext(AuthContext);
  const { data, loading, error } = useQuery(GET_TESTS, {fetchPolicy: "network-only"});
  const [validation, setValidation] = React.useState({});
  const [updateSubject] = useMutation(UPDATE_SUBJECT);
  const [updateStatus, {loadingUpdate}] = useMutation(UPDATE_STATUS, {
    onCompleted: async(data) => {
      showNotification("success", "Success!", "Test validated");
      console.log(data);
      if (data.update_test_result.returning[0].status === "Approved") {
        await updateSubject({
          variables: {
            uuid: data.update_test_result.returning[0].lab_subject.subject.uuid,
            obj: {
              last_test_validated_at: moment().toISOString()
            }
          }
        });
      }
    },
    refetchQueries: () => [
      {
        query: GET_TESTS
      }
    ]
  });

  const [count1, setCount1] = React.useState(null);
  const [count2, setCount2] = React.useState(null);

  const downloadUrl = async (event, publicUrl) => {
    event.preventDefault();
    const url = await getDownloadSignedUrl(publicUrl, {headers: {authorization: `Bearer ${authState.token}`}});
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = "doc";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const columns = [
    {
      title: "Test date",
      key: "test_date",
      render: record => {
        return <p>{moment(record.test_date).format("DD-MM-YYYY")}</p>;
      },
      sorter: (a, b) => moment(a.test_date).diff(moment(b.test_date)),
      sortDirections: ["ascend","descend"],
      defaultSortOrder: 'descend'
    },
    {
      title: "Lab",
      key: "lab",
      render: record => {
        return(
          <div>
            <p>{record.lab_subject.lab.lab_name}, {record.lab_subject.lab.address.city}</p>
            <p>ICMR/NABL certificate no: {record.lab_subject.lab.certificate_no}</p>
            <p>Validity: {moment(record.lab_subject.lab.validity).format("DD-MM-YYYY")}</p>
          </div>
        );
      },
      sorter: (a, b) => a.lab_subject.lab.lab_name.localeCompare(b.lab_subject.lab.lab_name),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "Test type",
      key: "test_type",
      filters: [
        { text: "COVID-19 Antibody", value: "COVIDAB" },
        { text: "COVID-19 Antigen", value: "COVIDAG" }
      ],
      onFilter: (value, record) => record.kit.test_type.includes(value),
      filterMultiple: false,
      render: record => {
        return <p>{record.kit.test_type === "COVIDAB" ? 
                    "COVID-19 Antibody" : 
                    "COVID-19 Antigen"}</p>;
      }
    },
    {
      title: "Kit",
      key: "kit",
      render: record => {
        return(
          <div>
            <p>Kit name: {record.kit.kit_name}</p>
            <p>Manufacturer: {record.kit.manufacturer}</p>
            <p>Kit type: {record.kit.kit_type}</p>
            <p>Kit/Sample ID: {record.kit_no}</p>
          </div>
        );
      },
      sorter: (a, b) => a.kit.kit_name.localeCompare(b.kit.kit_name),
      sortDirections: ["ascend","descend"]
    },
    {
      title: "Result",
      key: "result",
      render: record => {
        return (
          <div className="flex space-x-1">
            <p>{record.result ? "Positive" : "Negative"}</p>{" "}
            {record.image &&
            <Tooltip title="View">
              <Button
                icon={<EyeOutlined style={{verticalAlign: "middle"}}/>}
                shape="circle"
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                onClick={(event) => downloadUrl(event, record.image)}
              />
            </Tooltip>}
          </div>
        );
      }
    },
    {
      title: "Lab report",
      key: "lab_report",
      render: record => {
        return (record.lab_report.report &&
          <Tooltip title="Open document">
              <Button
                icon={<FileTextOutlined style={{verticalAlign: "middle"}}/>}
                shape="circle"
                type="primary"
                className="bg-gray-600 hover:bg-gray-500 border-none"
                onClick={(event) => downloadUrl(event, record.lab_report.report)}
              />
          </Tooltip>
        );
      }
    },
  ];
  const newTestsColumns = [...columns,
    {
      title: "",
      key: "validate",
      render: record => {
        return (
          <div className="flex space-x-2">
            <Radio.Group 
              value={record.uuid === validation.uuid ? validation.status : ""} 
              onChange={(e) => setValidation({status: e.target.value, uuid:record.uuid})}
            >
              <Radio value={"Approved"}>Approve</Radio>
              <Radio value={"Rejected"}>Reject</Radio>
            </Radio.Group>
            {record.uuid === validation.uuid ?
              <Popconfirm
                title="Are you sure?"
                onConfirm={async () => {
                  await updateStatus({
                    variables: validation
                  });
                  setValidation({});
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Submit">
                  <Button
                    type="primary"
                    icon={<SaveOutlined style={{verticalAlign: "middle"}} />}
                    shape="circle"
                    loading={loadingUpdate}
                    className="bg-teal-500 hover:bg-teal-400 border-none"
                  />
                </Tooltip>
              </Popconfirm> : 
            null}
          </div>
        );
      }
    }
  ];
  const validatedTestsColumns = [...columns,
    {
      title: "Validation status",
      key: "status",
      render: record => <p>{record.status}</p>,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["ascend","descend"],
      filters: [
        { text: "Approved", value: "Approved" },
        { text: "Rejected", value: "Rejected" }
      ],
      onFilter: (value, record) => record.status.includes(value),
      filterMultiple: false
    }
  ];

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Tests</h2>
      {/* <div className="mx-auto shadow-3xl rounded mt-32 pb-8 px-8 space-y-4 "> */}
      <div className="my-4 mx-auto overflow-x-auto text-gray-700">
        <Tabs defaultActiveKey="pending">
          <TabPane tab="Pending" key="pending">
            <Table
              title={() => <p className="font-semibold">Showing {count1 === null ? data.new_tests.length : count1} Entries</p>}
              dataSource={data.new_tests}
              columns={newTestsColumns}
              rowKey={(record) => record.uuid}
              locale={{emptyText:"No tests pending validation"}}
              expandable={{
                expandedRowRender: record => <p> Remarks: {record.remarks} </p>,
                rowExpandable: record => record.remarks,
              }}
              onChange={(pagination, filters, sorter, extra) => setCount1(extra.currentDataSource.length)}
            />
          </TabPane>
          <TabPane tab="Validated" key="Validated">
            <Table
              title={() => <p className="font-semibold">Showing {count2 === null ? data.validated_tests.length : count2} Entries</p>}
              dataSource={data.validated_tests}
              columns={validatedTestsColumns}
              rowKey={(record) => record.uuid}
              locale={{emptyText:"No validated tests yet"}}
              expandable={{
                expandedRowRender: record => <p> Remarks: {record.remarks} </p>,
                rowExpandable: record => record.remarks,
              }}
              onChange={(pagination, filters, sorter, extra) => setCount2(extra.currentDataSource.length)}
            />
          </TabPane>
        </Tabs>
      </div>
      <BackTop />
    </div>
  );
}
