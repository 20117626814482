import React from "react";
import { AuthContext, myFirebase } from "./authProvider";
import { withRouter } from "react-router-dom";
import "../styles/index.css";
import "antd/dist/antd.css";
import { Link, Redirect } from "react-router-dom";
import IHF_Logo from "../assets/IHF_Logo_TransBG.png";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Space, Tooltip, Button } from "antd";

export const Header = withRouter(props => <HeaderComponent {...props}/>);

class HeaderComponent extends React.Component {
    render() {
        return (
            <AuthContext.Consumer>
            {authState => {
                return (
                    <div>
                        <div className="w-full p-4 bg-header-custom shadow-md flex justify-between items-center">
                            <div className="space-x-1">
                                <Link to="/tests" className="hover:text-teal-600">
                                <div className="inline-block align-top">
                                    <img src={IHF_Logo} alt="logo" style={{ width: "2rem" }} />
                                </div>
                                <div className="inline-block align-bottom font-bold text-blue-800 text-xl">
                                    Capital IHF
                                </div>
                                </Link>
                            </div>
                            <div className="space-x-4">
                                {!!authState.user && authState.user.email && authState.user.emailVerified &&
                                    <div className="inline-block align-middle">
                                        <Space>
                                            <Tooltip title="Profile">
                                                <Button
                                                    icon={<UserOutlined style={{fontSize:24, verticalAlign: "middle"}}/>}
                                                    shape="circle"
                                                    type="primary"
                                                    className="bg-teal-500 hover:bg-teal-400 border-none"
                                                    onClick={() => this.props.history.push("/profile")}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Signout">
                                                <Button
                                                    icon={<PoweroffOutlined style={{fontSize:24, verticalAlign: "middle"}}/>}
                                                    shape="circle"
                                                    type="primary"
                                                    className="bg-teal-500 hover:bg-teal-400 border-none"
                                                    onClick={() => {
                                                        myFirebase
                                                        .auth()
                                                        .signOut()
                                                        .then(function() {
                                                            // Sign-out successful.
                                                            console.log("Successful");
                                                            return (
                                                            <Redirect to="/" />);
                                                        })
                                                        .catch(function(error) {
                                                            console.log("error", error);
                                                            // An error happened.
                                                        });
                                                    }}
                                                />
                                            </Tooltip>
                                        </Space>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                );
            }}
            </AuthContext.Consumer>
        );
    }
}