import * as React from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { Input, Spin, BackTop, Button } from "antd";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_USER_DETAILS } from "./profile";
import { showNotification } from "../utils/index";
import { UPDATE_USER, userDetailsSchema } from "./completeProfile";

export default function EditProfile(props) {
  const { loading, error, data } = useQuery(GET_USER_DETAILS);
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const onSubmit = async values => {
    console.log(values);

    await updateUserMutation({
      variables: {
        obj: {
          name: values.contact_name.trim()
        }
      }
    });

    props.history.push("/profile");
    showNotification("success", "Success!", "Your profile has been updated");
  };

  if (loading) return <div className="center-div-on-screen"><Spin size="large"/></div>;

  if (error) console.log(error);

  return (
    <div className="p-8 mx-auto">
      <h2 className="text-xl text-center text-teal-700 mb-4">Edit organisation profile</h2>
      <Formik
        initialValues={{
          contact_name: data.user[0].name
        }}
        validationSchema={userDetailsSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting
        }) => (
          <Form>
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold">Contact name</label>
              <Input
                size="large"
                type="text"
                name="contact_name"
                className={errors.contact_name && touched.contact_name ? "border-red-500" : "border-gray-400"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_name}
                
              />
              <div
                style={{
                  minHeight: 21
                }}
              >
                <ErrorMessage
                  name="contact_name"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>
            <Button
              size="middle"
              type="primary"
              className="bg-teal-500 hover:bg-teal-400 border-none"
              shape="round"
              block={true}
              htmlType="submit"
              loading={isSubmitting}
            >
              Update profile
            </Button>
          </Form>
        )}
      </Formik>
      <BackTop />
    </div>
  );
}
